import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import dataController from '../../data-controller/data-controller';

import MainPromo from '../main-promo/main-promo';
import BannerSlider from '../banner-slider/banner-slider';
import Shops from '../shops/shops';
import SEOTextMain from '../seo/main';
import PayoutPopup from './payout-popup/payout-popup';
import { jitsu } from '../../helpers/jitsu/jitsu';

function Index({ banners, logged = null, history }) {
  const [showPayPopup, setShowPayPopup] = useState(false);

  useEffect(() => {
    if (!banners) dataController.get(['banners']);
    if (logged) dataController.get(['banners']);
  }, [banners, logged]);

  useEffect(() => {
    if (document.location.search.includes('show_pay_popup')) {
      history.push({
        search: '',
      });
      setShowPayPopup(true);
    }
  }, [history]);

  return (
    <>
      {logged !== null && (
        <>
          {logged ? (
            <BannerSlider
              stickToTop
              banners={banners}
              logged
              onClick={({ id, deeplink, url }) => {

                jitsu.track('ClickToBanner', {event_value: id});

                if (deeplink) window.open(url, '_blank');
                else history.push(url);
              }}
            />
          ) : (
            <MainPromo />
          )}
        </>
      )}
      <Shops isPromocodesPage={false} isCategory={false} logged={logged} hidePagination isMain />
      {/* <PromocodePromo /> */}
      <SEOTextMain />
      <PayoutPopup show={showPayPopup} onClose={() => setShowPayPopup(false)} />
    </>
  );
}

Index.propTypes = {
  banners: PropTypes.object,
  logged: PropTypes.bool,
};

Index.defaultProps = {
  banners: undefined,
  logged: null,
};

const mapStateToProps = ({ other, user }) => ({
  banners: other.banners,
  logged: user.logged,
});

export default connect(mapStateToProps)(withRouter(Index));
