import React, { useState, useRef, useEffect } from 'react';
import './bottom-seo-text.scss';

export default function BottomSeoText({
  children,
  dangerously,
  closedHeight = 150,
  className = ''
}) {
  const [expanded, setExpanded] = useState();

  const [textHeight, setTextHeight] = useState();

  const textRef = useRef();

  useEffect(() => {
    setTextHeight(textRef.current.offsetHeight);
  }, []);

  useEffect(() => {
    if (closedHeight > textHeight) setExpanded(true);
  }, [closedHeight, textHeight]);

  return (
    <div className={`${className} seo-footer`}>
      {dangerously ? (
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: children }}
          className={`seo-footer__text ${expanded ? 'seo-footer__text--open' : ''}`}
          ref={textRef}
          style={{
            maxHeight: (() => {
              if (!textHeight) return 'unset';
              if (expanded) return `${textHeight}px`;
              return `${closedHeight}px`;
            })()
          }}
        />
      ) : (
        <div
          className={`seo-footer__text ${expanded ? 'seo-footer__text--open' : ''}`}
          ref={textRef}
          style={{
            maxHeight: (() => {
              if (!textHeight) return 'unset';
              if (expanded) return `${textHeight}px`;
              return `${closedHeight}px`;
            })()
          }}
        >
          {children}
        </div>
      )}
      {closedHeight < textHeight && (
        <div
          className={`seo-footer__btn ${expanded ? 'seo-footer__btn--open' : ''}`}
          onClick={() => {
            setExpanded(!expanded);
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60.25 60.25">
            <path d="M26.78,25.88h0l-4,4,4,4h0l18.8,18.8A4,4,0,0,1,40,58.35L11.56,29.92,40,1.5a4,4,0,0,1,5.59,5.59Z" />
          </svg>
        </div>
      )}
    </div>
  );
}
