import React from 'react';

const easySteps = [
  {
    title: <>Выбор предложений</>,
    icon: '/assets/svg/main-promo/easy-1.svg',
    text: <>На нашем&nbsp;сайте, вы найдете актуальные промокоды&nbsp;и&nbsp;предложения с кэшбэком от разных интернет-магазинов</>,
    id: 'easy-1'
  },
  {
    title: <>Выводите кэшбэк</>,
    icon: '/assets/svg/main-promo/easy-2.svg',
    text: (
      <>
        Выберете магазин, перейдите на него и совершите покупку. Получите кэшбэк на счет в личном
        кабинете, с возможностью вывода на мобильный телефон, банковскую карту и т.д.
      </>
    ),
    id: 'easy-2'
  },
  {
    title: <>Используйте промокоды</>,
    icon: '/assets/svg/main-promo/easy-3.svg',
    text: (
      <>
        Кликните по промокоду для копирования и вставьте его при оформлении заказа в магазине, чтобы
        получить скидку
      </>
    ),
    id: 'easy-3'
  }
];

const trustSteps = [
  {
    title: <>Высокий кэшбэк</>,
    icon: '/assets/svg/main-promo/trust-1.svg',
    text: <>на множество товаров, от&nbsp;ручки до&nbsp;холодильника</>,
    id: 'trust-1'
  },
  {
    title: <>Вывод от&nbsp;1&nbsp;рубля</>,
    icon: '/assets/svg/main-promo/trust-2.svg',
    text: <>не нужно копить кэшбэк</>,
    id: 'trust-2'
  },
  {
    title: <>Мгновенный вывод средств</>,
    icon: '/assets/svg/main-promo/trust-3.svg',
    text: <>на банковскую карту, электронный кошелек или мобильный телефон</>,
    id: 'trust-3'
  }
];

export default { easySteps, trustSteps };
