import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-bootstrap';

import ProgramList from './program-list/program-list';
import ProgramFilters from './program-filters/program-filters';
import ShowMore from './program-list/show-more';
import ShopsCount from './program-list/shops-count';
import getCountWord from "../../helpers/utils/get-count-word";

function useFakeCount({ itemsCount, category, isPromocodesPage }) {
  const counter = useCallback(() => {
    if (!isPromocodesPage && !category.value) return itemsCount + 1000;
    return itemsCount;
  }, [category.value, isPromocodesPage, itemsCount]);

  return counter();
}

function ProgramContainer({
  searchType,
  items,
  categories,
  titleTop,
  subtitleTop,
  types,
  showCatName,
  hideTitle,
  favIds,
  onClickShowMore,
  showMoreUrl,
  onChangeFilters,
  category = {},
  country = {},
  type = {},
  isPromocodesPage,
  itemsCount = 0,
  countries,
  subBanner,
}) {
  //const fakeCount = useFakeCount({ itemsCount, category, isPromocodesPage });

  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <ProgramFilters
            searchType={searchType}
            categories={
              isPromocodesPage
                ? categories.filter(({ withPromocodes }) => withPromocodes)
                : categories
            }
            types={searchType === 'promocodes' ? null : types}
            countries={countries}
            onChange={onChangeFilters}
            category={category}
            country={country}
            type={type}
          />

          <div className="promocode_banner">{subBanner}</div>

          {titleTop && !hideTitle && (
            <div
              className={`program-list__title ${showCatName ? 'program-list__title_show-cat' : ''}`}
            >
              {!category.value &&
                <div className="title-count">
                  Магазины с {isPromocodesPage ? 'промокодами' : 'кэшбэком'}
                </div>
              }
              {category && category.value && <div className="title-cat">{category.label}</div>}
              {/* subtitleTop && <div className="title-desc">{subtitleTop}</div> */}
              {/* category.label */}
            </div>
          )}
          <ProgramList
            searchType={searchType}
            noTitle={titleTop}
            items={items}
            pageSize={20}
            favIds={favIds}
          />
          {showMoreUrl && <ShowMore onClick={onClickShowMore} url={showMoreUrl} />}
        </Col>
      </Row>
    </Grid>
  );
}

const mapStateToProps = ({ user, appReducer }) => ({
  favIds: user.user.favorites && user.user.favorites.data.map(({ id }) => id),
  logged: user.logged,
  nodeUrl: appReducer.nodeUrl,
});

export default connect(mapStateToProps)(ProgramContainer);
