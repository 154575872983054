/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import ProgramCard from '../../../program/program-list/program-card';

import './cards.scss';

Cards.propTypes = {
  cards: PropTypes.shape({
    left: PropTypes.object,
    center: PropTypes.object,
    right: PropTypes.object
  })
};

Cards.defaultProps = {
  cards: []
};

export default function Cards({ cards }) {
  return (
    <div className="promo__cards">
      {cards.left && (
        <ProgramCard className="left" {...cards.left} uriCode={cards.left.uriCode} noFlat noFav />
      )}
      {cards.right && (
        <ProgramCard
          className="right"
          {...cards.right}
          uriCode={cards.right.uriCode}
          noFlat
          noFav
        />
      )}
      {cards.center && (
        <ProgramCard
          className="center"
          {...cards.center}
          uriCode={cards.center.uriCode}
          noFlat
          noFav
        />
      )}
    </div>
  );
}
