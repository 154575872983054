import React from "react";
import { Grid, Row, Col } from 'react-bootstrap';
import BottomSeoText from "./bottom-seo-text";

export default function SEOTextMain() {
  return (
    <Grid>
      <Row>
        <Col xs={12}>
          <BottomSeoText>
            <h1>Маркетплейс скидок Скидка&nbsp;ру</h1>
            <p>Добро пожаловать на сайт Скидка ру — простой и удобный онлайн-шоппинг, главными инструментами которого являются кэшбэк и промокоды.
              Мы активно растём, поэтому рады сообщить о расширении возможностей нашего сервиса.
              Наша новая роль — маркетплейс скидок. Помимо кэшбэка, мы предлагаем вам промокоды, которые позволяют получать максимальную выгоду мгновенно.
              Вам не нужно ждать, скидку вы получите сразу. Следите за обновлениями и меняйте с нами подход к совершению покупок.</p>
            <h2>С нами выгодно</h2>
            <p>
              На нашем сайте вы найдете тысячи актуальных промокодов и предложений кэшбэка в разнообразных интернет-магазинах.
              Лучшие скидки в одном месте для вашего удобства. Мы заботимся о наших пользователях: стараемся и сохраняем максимально выгодные условия.
              Выводите кэшбэк удобным из представленных для вас способов.
            </p>
            <h2>Как работает маркетплейс скидок?</h2>
            <p>Наш сервис подключает к своей платформе онлайн–магазины и размещает широкий выбор промокодов.
              Пользователи приходят в магазины через наш сервис и совершают покупки.
              За эти покупки магазины предоставляют значительную скидку пользователям сервиса.
              Деньги возвращаются покупателю на счёт в личном кабинете маркетплейса скидок и выплачиваются после подтверждения заказа.
              Используйте промокоды при оформлении заказа на сайте магазина. Вводите промокод в специальное поле и получайте различные виды скидок и бонусов.
              Мы расширяем спектр доступных вам скидок и выгодных предложений, чтобы покупки стали еще более удобными.
              Мы ценим каждого нашего пользователя и благодарим вас за доверие и выбор Скидка.ру
              Спасибо, что вы с нами.
            </p>
          </BottomSeoText>
        </Col>
      </Row>
    </Grid>
  );
}
