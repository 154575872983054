import { useEffect, useRef } from "react";

import useProgramsState from '../../../context/programs-context/hooks/use-programs-state';
import useProgramsActions from '../../../context/programs-context/hooks/use-programs-actions';
import { useLocation, useRouteMatch } from 'react-router';

/**
 * Хук запроса данных для каталога
 * @param {boolean} params.isCategory - это категория
 */
export default function useShopsData({ isPromocodesPage, isLogged }) {
  /**
   * Данные и методы из контекста
   */
  const {
    programs,
    countries,
    categories,
    types,
    category,
    country,
    type,
    programsCount,
    favPrograms
  } = useProgramsState();
  const { getPrograms, getCategories } = useProgramsActions();

  /**
   * Если нет категорий, то запросить
   */
  useEffect(() => {
    if (!categories || categories.length === 0) getCategories();
  }, [categories, getCategories]);

  /**
   * Если нет стран, то запросить
   */
  // useEffect(() => {
  //   if (!countries || countries.length === 0) getCountries();
  // }, [countries, getCountries]);

  /**
   * Запросить магазины, если изменились фильтры
   */
  const match = useRouteMatch();

  // !!!! жуткий костыль, хрень какая-то. Приходит category то так, то эдак, так же isPromocodePage
  // искать откуда ноги растут нет времени, сделаем такой подход:
  // если меняется категория на конкретную, запрашиваем магазины по первому useEffect
  // если категорию сбросили или переключили страницу на промокоды/кб то по второму useEffect
  useEffect(() => {
    if (
      match.params.categoryName &&
      category?.value &&
      match.params.categoryName === category.translitName
    ) {
      getPrograms({
        filters: { category, type: isPromocodesPage ? 'hasPromocodes' : 'noCashback' },
        isLogged,
      });
    }
  }, [category, match.params.categoryName]);

  useEffect(() => {
    if (category || match.params.categoryName) return;
    getPrograms({
      filters: { type: isPromocodesPage ? 'hasPromocodes' : 'noCashback' },
      isLogged,
    });
  }, [category, isPromocodesPage, isLogged]);

  return {
    programs,
    categories,
    countries,
    types,
    category,
    country,
    type,
    programsCount,
    favPrograms
  };
}
