import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';

import useProgramsState from 'src/context/programs-context/hooks/use-programs-state';
import useProgramsActions from 'src/context/programs-context/hooks/use-programs-actions';

import Cards from '../components/cards/cards';
import People from '../components/people/people';
import HeaderLeft from '../components/header-left/header-left';
import HeaderRight from '../components/header-right/header-right';

import './main-header.scss';
import Wallet from "../components/wallet/wallet";
import Button from "../../shared/button/button";
import { Link } from "react-router-dom";
import useWindowSize from "../../shared/hooks/window-size";

function getDataForPromoCards(programs) {
  const ids = { left: '72', center: '233', right: '936' };
  const promoData = {};
  programs.forEach((program) => {
    if (ids.left === program.id) promoData.left = program;
    if (ids.center === program.id) promoData.center = program;
    if (ids.right === program.id) promoData.right = program;
  });

  return promoData;
}

function MainHeader() {
  /* const { promoPrograms } = useProgramsState();
  const { getPromoPrograms } = useProgramsActions();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (!promoPrograms) getPromoPrograms();
    else setCards(getDataForPromoCards(promoPrograms || []));
  }, [getPromoPrograms, promoPrograms]); */

  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width <= 991);
  }, [width]);

  return (
    <>
      <div className="main-header">
        <Grid className="promo">
          <Row className="promo__top-row">
            <Col xs={12} sm={12} md={6} lg={7}>
              <HeaderLeft />
            </Col>
            <Col xs={0} md={6} lg={6} className="header-content__right">
              <Wallet />
              {/* <People /> */}
              {/* {cards && <Cards cards={cards} />} */}
            </Col>
          </Row>
        </Grid>
      </div>
      <div className="promo-promocodes-banner">
        <Grid>
          <Row className="promocode_banner">
            <Col xs={12}>
              {(() => {
                if (isMobile) {
                  return <div className="promocode-banner-mobile promocode-banner-mobile-noreg">
                          <a href="https://t.me/skidkaru_promokod">
                            <img className="promocode-banner-noreg" src="/assets/img/main-promo/tgbanner-noreg-mob.jpg" alt="" />
                          </a>
                        </div>;
                }
                  return <div className="promocode-banner-desktop">
                          <a href="https://t.me/skidkaru_promokod">
                            <img className="promocode-banner-noreg" src="/assets/img/main-promo/tgbanner-noreg-desktop.jpg" alt="" />
                          </a>
                        </div>
              })()}
            </Col>
          </Row>
        </Grid>
      </div>
    </>
  );
}

export default MainHeader;
