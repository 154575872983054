import React from 'react';
import SlickSlider, { Settings } from 'react-slick';
import './scss/_entry.scss';

interface ArrowMainSliderProps {
  onClick?: () => void;
  type: 'prev' | 'next';
}

function ArrowMainSlider({ onClick, type }: ArrowMainSliderProps): React.ReactElement {
  return (
    <div className={`main-slider__arrow ${type === 'prev' ? 'left' : 'right'}`} onClick={onClick}>
      <img src={`/assets/img/arrow-${type === 'prev' ? 'left' : 'right'}.png`} alt="" />
    </div>
  );
}

interface DotProps {
  onClick: () => void;
  active: boolean;
}

function Dot({ active, onClick }: DotProps): React.ReactElement {
  return <div className={`main-slider__dot ${active ? 'active' : ''}`} onClick={onClick} />;
}

interface SliderProps extends Settings {
  children: React.ReactNode[];
  infinite?: boolean;
}

export default function Slider({
  children,
  infinite,
  ...sliderProps
}: SliderProps): React.ReactElement {
  return (
    <div className="main-slider">
      <SlickSlider
        dots
        infinite={infinite}
        speed={500}
        slidesToShow={1}
        slidesToScroll={1}
        touchThreshold={100}
        prevArrow={<ArrowMainSlider type="prev" />}
        nextArrow={<ArrowMainSlider type="next" />}
        // @ts-expect-error похоже, что здесь проблема с типами библиотеки
        customPaging={(props) => <Dot {...props} />}
        appendDots={(dots) => <div className="main-slider__dots">{dots}</div>}
        {...sliderProps}
      >
        {children}
      </SlickSlider>
    </div>
  );
}
