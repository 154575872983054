import { useState, useEffect } from 'react';
import useWindowSize from '../../shared/hooks/window-size';

export default function useCardsData(items) {
  const { width } = useWindowSize(0);
  const [itemsPerLine, setItemsPerLine] = useState(0);

  useEffect(() => {
    if (width <= 600) {
      setItemsPerLine(1);
      return;
    }
    if (width <= 991) {
      setItemsPerLine(3);
      return;
    }
    if (width <= 1199) {
      setItemsPerLine(4);
      return;
    }
    setItemsPerLine(5);
  }, [width]);

  return itemsPerLine
    ? [
        ...items,
        ...new Array(items.length % itemsPerLine ? itemsPerLine - (items.length % itemsPerLine) : 0)
      ]
    : items;
}
