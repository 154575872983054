import React, { useEffect, useState } from 'react';
import Button from '../../shared/button/button';
import './program-list.scss';

export default function ShowMore({ onClick, url }) {
  const [scrollPosition, setScrollPosition] = useState(null);

  useEffect(() => {
    // Костыль для того чтобы chrome не проматывал магазины
    // когда нажимают "показать еще"
    if (scrollPosition)
      setTimeout(() => {
        window.scroll(...scrollPosition);
      }, 300);
  }, [scrollPosition]);

  return (
    <a
      href={url}
      onClick={(e) => {
        e.preventDefault();
      }}
      className="program-list__show-more"
    >
      <Button
        onClick={() => {
          setScrollPosition([window.scrollX, window.scrollY]);
          onClick();
        }}
        type="secondary"
      >
        Показать ещё
      </Button>
    </a>
  );
}
