import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../button/button';
import './bullet-slider.scss';

type Option = { value: string; label?: string; url?: string };

interface BulletSliderProps {
  value: Option;
  options: Option[];
  onSelect: (value: Option) => void;
}

export default function BulletSlider({
  value = { value: null },
  options = [],
  onSelect,
}: BulletSliderProps): React.ReactNode {
  const [blurLeft, setBlurLeft] = useState(false);
  const [blurRight, setBlurRight] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    if (value.value && window.innerWidth < 990) {
      const currentPointEl = document.getElementById(`bullet-${value.value}`);
      currentPointEl.scrollIntoView({ block: 'center' });
    }
  }, [value]);

  useEffect(() => {
    if (ref?.current) {
      setBlurLeft(ref.current.scrollLeft > 0);
      setBlurRight(ref.current.scrollWidth - ref.current.clientWidth - ref.current.scrollLeft >= 0);
    }
  }, [ref]);

  return (
    <div
      className={`bullets-slider_wrapper ${blurLeft ? 'blur-left' : ''} ${
        blurRight ? 'blur-right' : ''
      }`}
    >
      <ul
        className="bullets-slider"
        id="bullets-slider"
        onScroll={({ currentTarget }) => {
          setBlurLeft(currentTarget.scrollLeft > 0);
          setBlurRight(
            currentTarget.scrollWidth - currentTarget.clientWidth - currentTarget.scrollLeft > 0,
          );
        }}
        ref={ref}
      >
        {options.map(({ value: optionValue, label, url, ...rest }) => {
          const Wrapper = ({ children }: { children: React.ReactNode }) =>
            url ? <Link to={url}>{children}</Link> : <>{children}</>;

          return (
            <li className="bullet-item" key={`bullet-${optionValue}`} id={`bullet-${optionValue}`}>
              <Wrapper>
                <Button
                  active={optionValue === value?.value}
                  type="bullet"
                  onClick={() => onSelect({ value: optionValue, label, ...rest })}
                >
                  {label}
                </Button>
              </Wrapper>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
