/* eslint-disable no-underscore-dangle */
// TODO eslint
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Slider from '../shared/slider/slider';
import Slide from '../shared/slider/slide';

const bannerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  image: PropTypes.string,
  deeplink: PropTypes.string,
  url: PropTypes.string,
});

BannerSlider.propTypes = {
  banners: PropTypes.shape({
    mobile: PropTypes.arrayOf(bannerPropTypes),
    desktop: PropTypes.arrayOf(bannerPropTypes),
    desktop_not_auth: PropTypes.arrayOf(bannerPropTypes),
  }),
  stickToTop: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  logged: PropTypes.bool,
};

BannerSlider.defaultProps = {
  banners: {
    mobile: [],
    desktop: [],
    desktop_not_auth: [],
  },
  stickToTop: false,
  logged: false,
};

function BannerSlider({ banners, stickToTop, onClick, logged }) {
  return (
    <Grid>
      {logged ? (
        <Row>
          <Col xs={12}>
            <div className="main-slider__container desktop">
              <Slider infinite showArrows slidesLength={banners.desktop.length}>
                {banners.desktop.length === 0 && <div className="blank-slide" />}
                {banners.desktop.map(({ id, image, ...rest }) => (
                  <Slide
                    key={id}
                    src={image}
                    alt="Рекламный баннер"
                    onClick={() => onClick({ id, image, ...rest })}
                  />
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col xs={12}>
            <div className="main-slider__container desktop">
              <Slider infinite showArrows slidesLength={banners.desktop_not_auth.length}>
                {banners.desktop_not_auth.length === 0 && <div className="blank-slide" />}
                {banners.desktop_not_auth.map(({ id, image, ...rest }) => (
                  <Slide
                    key={id}
                    src={image}
                    alt="Рекламный баннер"
                    onClick={() => onClick({ id, image, ...rest })}
                  />
                ))}
              </Slider>
            </div>
          </Col>
        </Row>
      )}

      <div className={`main-slider__container mobile ${stickToTop ? 'stick-to-top' : ''}`}>
        <Slider infinite>
          {banners.mobile.length === 0 && <div className="blank-slide" />}
          {banners.mobile.map(({ id, image, ...rest }) => (
            <Slide
              key={id}
              src={image}
              alt="Рекламный баннер"
              onClick={() => onClick({ id, image, ...rest })}
            />
          ))}
        </Slider>
      </div>
    </Grid>
  );
}

export default withRouter(BannerSlider);
