/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import onEnter from '../../../helpers/utils/on-enter';

export default function Slide({ src, alt, onClick }) {
  // Фикс для пролистывания мышкой баннеров на мобильном виде,
  // там регистрировался клик, совершался переход
  const [moveDelta, setMoveDelta] = useState({ x: 0, y: 0, prevX: 0, prevY: 0 });

  return (
    <img
      className="slide-img"
      src={src}
      alt={alt}
      onMouseDown={({ screenX: prevX, screenY: prevY }) => {
        setMoveDelta({ ...moveDelta, prevX, prevY });
      }}
      onMouseUp={({ screenX: x, screenY: y }) => {
        setMoveDelta({ ...moveDelta, x, y });
      }}
      onClick={() => {
        if (
          Math.abs(moveDelta.prevX - moveDelta.x) > 20 ||
          Math.abs(moveDelta.y - moveDelta.prevY) > 20
        )
          return;
        onClick();
      }}
      onKeyPress={() => onEnter().onKeyPress(onClick)}
    />
  );
}
