import React from 'react';
import { Grid, Row, Col } from 'react-bootstrap';

import MainHeader from './banners/main-header';
import stepsData from './components/steps/steps-data';
import Steps from './components/steps/steps';

function MainPromo() {
  return (
    <>
      <MainHeader />
      <Grid>
        <Row>
          <Col xs={12}>
            <Steps sectionTitle="Как работает маркетплейс скидок" steps={stepsData.easySteps} />
          </Col>
        </Row>
        {/* <Row>
          <Col xs={12}>
            <Steps
              sectionTitle="Почему нам доверяют более 500&nbsp;000 человек?"
              steps={stepsData.trustSteps}
            />
          </Col>
        </Row> */}
      </Grid>
    </>
  );
}

export default MainPromo;
