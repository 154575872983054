import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../shared/button/button';

export default function HeaderLeft() {
  return (
    <div className="header-content__left">
      <div className="main__title main__title_top_banner">
        Маркетплейс скидок
      </div>
      <div className="header__small-info">
        Получайте мгновенную скидку, используя раздел
        <br />
        промокоды. Покупайте онлайн в любимых магазинах
        <br />
        выгодно с помощью кэшбэка.
      </div>
      <Link to="/user/reg/" className="reg-button">
        <Button type="arrow-right">Зарегистрироваться</Button>
      </Link>
    </div>
  );
}
