import React from 'react';
import dayjs from 'dayjs';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ProgramCard from './program-card';
import PromocodeCardSmall from '../../promocode/promocode-card/promocode-card-small/promocode-card-small';
import User from '../../../json-api/models/user/user';
import Favorite from '../../../json-api/models/user/favorites';
import useCardsData from './use-cards-data';
import useProgramsActions from '../../../context/programs-context/hooks/use-programs-actions';

import './program-list.scss';
import PromocodeShopCard from '../../promocode/promocode-card/promocode-shop-card/promocode-shop-card';

function ProgramList({
  items,
  noTitle,
  searchType,
  favIds,
  noFav,
  userId,
  updateFavorites,
  history
}) {
  const cardsData = useCardsData(items);
  const { addFavProgram, deleteFavProgram } = useProgramsActions();

  async function onClickFav(id) {
    if (favIds) {
      const userModel = new User();
      userModel.id = userId;
      const favoriteModel = new Favorite();
      favoriteModel.id = id;

      if (favIds.includes(id)) {
        deleteFavProgram(id);
        const data = await userModel.deleteRelated(favoriteModel, { inArray: false });
        updateFavorites(data);
      } else {
        const data = await userModel.createRelated(favoriteModel, { inArray: false });
        updateFavorites(data);
        addFavProgram(items.find(({ id: programId }) => programId === id));
      }
    } else {
      history.push('/user/auth/');
    }
  }

  return (
    <div className="program-list__wrapper">
      {!noTitle && (
        <div className="program-list__title">
          <span className="title-count">Магазины с кэшбэком</span>
          <span className="title-desc">Просто переходите, покупайте и получайте кэшбэк</span>
        </div>
      )}

      <div className="program-list">
        {cardsData.length === 0 && 'Здесь пока ничего нет'}
        {cardsData.map((item, i) => {
          if (item) {
            if (searchType === 'promocodes') {
              return (
                <PromocodeShopCard
                  logo={item.logo}
                  count={item.promocodes?.data?.length}
                  isFav={favIds && favIds.includes(item.id)}
                  onClickFav={() => onClickFav(item.id)}
                  uriCode={item.uriCode}
                />
              );
            }
            return (
              <ProgramCard
                {...item}
                uriCode={item.uriCode}
                key={item.id}
                isFav={favIds && favIds.includes(item.id)}
                onClickFav={onClickFav}
                noFav={noFav}
                logo={item.logo}
                isPromocodesPage={searchType === 'promocodes'}
                promocodesCount={item.promocodes?.data?.length}
              />
            );
          }
          return <ProgramCard key={`empty-card-${i}`} className="card_empty" />;
        })}
      </div>
    </div>
  );
}

const mapStateToProps = ({ user }) => ({
  userId: user.user.id
});

const mapDispatchToProps = (dispatch) => ({
  updateFavorites: (data) => dispatch({ type: 'UPDATE_USER', payload: { type: 'favorites', data } })
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProgramList));
