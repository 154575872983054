import React from 'react';
import './promocode-card-small.scss';
import { Link } from 'react-router-dom';
import cyrillicToTranslit from 'cyrillic-to-translit-js';
import Image from '../../../shared/image/image';

export default function PromocodeCardSmall({ id, logo, descr, dateExp, mobile }) {
  return (
    <Link className="card promocode-small" to={`/promocode/details/${createLink(descr, id)}/`}>
      <div className="card__logo-wrapper">
        <Image className="card__logo" src={logo} alt="" />
      </div>
      <div className="card__info">
        <div className="promocode-title">{descr}</div>
        <div className="promocode-date">
          {mobile ? 'действует' : ''} до <b>{dateExp}</b>
        </div>
      </div>
    </Link>
  );
}

function createLink(descr, id) {
  return encodeURIComponent(
    `${cyrillicToTranslit()
      .transform(descr.toLowerCase(), '-')
      .replace(' ', '')
      .replace('!', '')
      .replace('%', '')
      .replace('/', '')}-${id}`
  );
}
