import React from 'react';
import { Link } from 'react-router-dom';
import './promocode-shop-cards.scss';
import '../promocode-card.scss';

export default function PromocodeShopCard({ logo, count, isFav, onClickFav, uriCode }) {
  return (
    <Link
      className="card flat-mobile promocode-shop-card promocode-card promocode-card_horizontal with-shadow"
      to={`/promocode/details/${encodeURIComponent(uriCode)}/`}
    >
      <img className="card__logo" src={logo} alt="" />
      <div className="card__bottom">
        <div className="promocodes-count">
          <span className="title">Промокоды</span>
          <span className="count">{count}</span>
        </div>
        <svg
          className={`cb-fav ${isFav ? 'on' : ''}`}
          onClick={(e) => {
            e.preventDefault();
            onClickFav();
          }}
        >
          <use xlinkHref="/assets/svg/sprite-v2.svg#heart" />
        </svg>
      </div>
    </Link>
  );
}
