import React from 'react';
import getCountWord from '../../../helpers/utils/get-count-word';

export default function ShopsCount({ count, searchType }) {
  return (
    <div className="title-count">{`${count} ${getCountWord('магазин', count)} ${
      searchType === 'promocodes' ? ' с промокодами' : ''
    }`}</div>
  );
}
