import React from 'react';
import PropTypes from 'prop-types';

import './steps.scss';

Steps.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.element,
      icon: PropTypes.string,
      text: PropTypes.element,
      id: PropTypes.string
    })
  ).isRequired
};

export default function Steps({ sectionTitle, steps }) {
  return (
    <div className="main__steps-wrapper">
      <div className="main__title">{sectionTitle}</div>
      <div className="main__steps mmt30 dmt40">
        {steps.map(({ title, icon, text, id }) => (
          <div className="main__step" key={id}>
            <div className="step-icon first">
              <img src={icon} alt="" />
            </div>
            <div className="step-info">
              <div className="step-title">{title}</div>
              <div className="step-text">{text}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
