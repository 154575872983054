import React from 'react';

import './wallet.scss';

export default function Wallet() {
  return (
    <div className="main-header__people">
      <img
        className="person person_girl person_desktop"
        src="/assets/img/main-promo/package.png"
        alt=""
      />
    </div>
  );
}
