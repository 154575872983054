import React, { useCallback, useEffect, useState } from "react";
import { connect } from 'react-redux';
import { withRouter, Route, Redirect, useParams } from 'react-router';
import { Grid, Row, Col } from 'react-bootstrap';

import ProgramContainer from '../program/program-container';
import BottomSeoText from '../seo/bottom-seo-text';
import isNode from '../../helpers/utils/is-node';
import BreadCrumbs from '../shared/bread-crumbs/bread-crumbs';
import useShopsData from './helpers/use-shops-data';
import useProgramsActions from '../../context/programs-context/hooks/use-programs-actions';
import useShowMoreUrl from './helpers/use-show-more-url';
import { Link } from "react-router-dom";
import useWindowSize from "../shared/hooks/window-size";
import { padding } from "stylelint-order/rules/shorthandData";

function Shops({
  hidePagination,
  setMeta,
  logged,
  history,
  favIds,
  isPromocodesPage,
  isMain,
  isLanding,
  countries,
}) {
  const {
    programs,
    categories,
    types,
    category,
    country,
    type,
    programsCount,
    favPrograms,
  } = useShopsData({ isPromocodesPage: isPromocodesPage, isLogged: logged });

  if (isNode() && setMeta && category?.value) setMeta({ title: category?.label });

  const showMoreUrl = useShowMoreUrl();

  const { appendNextPrograms } = useProgramsActions();
  const { setCategory, setCountry, setType, getFavPrograms } = useProgramsActions();
  // console.log(countries);
  useEffect(() => {
    if (category?.notFound && !isLanding) history.push('/404/');
    if (setMeta) {
      setMeta({ title: category?.label ? category.label : isPromocodesPage ? 'Промокоды' : 'Магазины' });
    }
  }, [category, history, isLanding, setMeta]);

  const { categoryName } = useParams();

  useEffect(() => {
    setCategory(categories.find(({ translitName }) => translitName === categoryName));
  }, [categories, categoryName, setCategory]);

  useEffect(() => {
    if (!logged || category?.value || country?.value || type?.value) return;
    if (favIds.length !== 0 && favPrograms.length === 0) getFavPrograms({ favIds });
  }, [
    category?.value,
    country?.value,
    type?.value,
    favIds.length,
    favPrograms.length,
    getFavPrograms,
    logged,
  ]);

  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(width <= 991);
  }, [width]);

  const promoBann = useCallback(() => {
    if (!logged) {
      return '';
    }

    if (isMobile) {
      return <div className="promocode-banner-mobile bottom-offset20-mob">
                <a href="https://t.me/skidkaru_promokod" target="_blank">
                  <img className="promocode-banner-reg" src="/assets/img/main-promo/tgbanner-reg-mob.jpg" alt="" />
                </a>
              </div>
      }

        return <div className="promocode-banner-desktop bottom-offset20">
                <a href="https://t.me/skidkaru_promokod" target="_blank">
                  <img className="promocode-banner-reg" src="/assets/img/main-promo/tgbanner-reg-desktop.jpg" alt="" />
                </a>
              </div>;
  }, [logged, isMobile]);

  return (
    <>
      {category && category.notFound && !isLanding && (
        <Route
          render={({ staticContext }) => {
            if (staticContext) staticContext.status = 404;
            return null;
          }}
        />
      )}
      {isNode() && !logged && category?.value === 'fav' && (
        <Route
          render={({ staticContext }) => {
            if (staticContext) staticContext.status = 301;
            return <Redirect to={isPromocodesPage ? '/promocode/' : '/shops/'} />;
          }}
        />
      )}
      {!hidePagination && (
        <Grid>
          <Row>
            <Col xs={12}>
              <BreadCrumbs
                pages={[
                  {
                    title: isPromocodesPage ? 'Промокоды' : 'Магазины',
                    url: isPromocodesPage ? '/promocode/' : '/shops/',
                  },
                  category?.value ? { title: category.label } : {},
                ]}
              />
            </Col>
          </Row>
        </Grid>
      )}

      {programs && (
        <ProgramContainer
          itemsCount={programsCount}
          types={types}
          searchType={isPromocodesPage ? 'promocodes' : 'programs'}
          titleTop
          items={
            !category?.value && !country?.value && !type?.value
              ? [
                  ...(isPromocodesPage
                    ? favPrograms.filter(({ promocodes }) => promocodes.data.length !== 0)
                    : favPrograms.filter(({ noCashback }) => !noCashback)),
                  ...programs.filter(({ id }) => !favIds.includes(id)),
                ]
              : programs
          }
          categories={categories}
          countries={countries}
          showCatName={category?.value}
          currentCategory={category}
          subtitleTop={category?.value && 'Просто переходите, покупайте и получайте кэшбэк'}
          onClickShowMore={appendNextPrograms}
          showMoreUrl={showMoreUrl}
          onChangeFilters={({ type: actionType, value }) => {
            if (actionType === 'type') setType(value);
            if (actionType === 'country') setCountry(value);
          }}
          category={category?.value ? category : categories[0]}
          country={country}
          type={type}
          isPromocodesPage={isPromocodesPage}
          subBanner={isMain ? promoBann() : ''}
        />
      )}
      <Grid>
        <Row>
          <Col xs={12}>
        {isPromocodesPage ? <span style={{fontSize: 0}}>qzzpdc0tsbtqug7u</span> : ''}
            {!category?.name && !isMain && !isPromocodesPage && (
        <BottomSeoText>
          Интернет-магазины с кэшбэком. Наша цель – сделать ваши покупки как можно дешевле. А
          главным элементом экономии мы сделали кэшбэк.
        </BottomSeoText>
      )}
            {category?.name && category?.seoText && (
              <BottomSeoText dangerously>{category.seoText}</BottomSeoText>
            )}
          </Col>
        </Row>
      </Grid>
    </>
  );
}

const mapStateToProps = ({ programs, appReducer, user }) => ({
  programs: programs.programsData,
  nodeUrl: appReducer.nodeUrl,
  serverRoute: appReducer.serverRoute,
  logged: user.logged,
  favIds: user.user.favorites ? user.user.favorites.data.map(({ id }) => id) : [],
  countries: user.user.delivery_countries,
});

export default connect(mapStateToProps)(withRouter(Shops));
