import React from 'react';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import './payout-popup.scss';

PayoutPopup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

PayoutPopup.defaultProps = {
  show: false
};

export default function PayoutPopup({ show, onClose }) {
  return (
    <Modal className="styled-modal modal centered payout-index-modal" show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Перевод денежных средств успешно&nbsp;осуществлён</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="main-text">
          Среднее время поступления денег на счёт 10 минут.
          <br /> В редких случаях перевод на банковские карты может достигать 5 дней.
        </div>
        <div className="secondary-text">
          Если у вас возникли сложности с переводом денежных средств, пожалуйста, обращайтесь к нам
          через <Link to="/user/support/new/?themeId=8">службу&nbsp;поддержки</Link>.
        </div>
      </Modal.Body>
    </Modal>
  );
}
