import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import useDebounce from '../../shared/hooks/debounce-hook';
import Programs from '../../../json-api/models/programs/programs';
import ProgramsWithTokens from '../../../json-api/models/programs/programs-with-tokens';
import SearchInputV2 from '../../shared/search-input-v2/search-input-v2';

function ProgramsSearchField({ history, searchType, country }) {
  const [query, setQuery] = useState('');
  const [resultsData, setResultsData] = useState([]);
  const [dataIsPending, setDataIsPending] = useState(false);
  const [canShowNoOptions, setCanShowNoOptions] = useState(false);
  const queryDebounced = useDebounce(query, 500);
  useEffect(() => {
    if (queryDebounced && queryDebounced.length >= 2) {
      setCanShowNoOptions(false);
      let programModel;

      if (country !== undefined) {
        programModel = new ProgramsWithTokens();
      } else {
        programModel = new Programs();
      }
      (async () => {
        setDataIsPending(true);
        let programFiltered = programModel.where('name', queryDebounced);

        if (searchType === 'promocodes')
          programFiltered = programFiltered.where('hasPromocodes', true);
        else programFiltered = programFiltered.where('noCashback', false);

        const { data: programs } = await programFiltered.get();
        if (programs.length === 0) {
          setCanShowNoOptions(true);
        } else {
          setCanShowNoOptions(false);
        }
        setDataIsPending(false);
        setResultsData(
          programs.map((program) => ({
            label: program.program_misc.name,
            value: program.id,
            uri: program.uriCode,
          })),
        );
      })();
    }
  }, [queryDebounced, searchType, country]);

  return (
    <SearchInputV2
      placeholder={
        searchType === 'promocodes' ? 'Найти магазин с промокодами' : 'Найти магазин с кэшбэком'
      }
      instanceId="shop"
      filterOption={(item) => item}
      inputValue={query}
      onInputChange={(val, { action }) => {
        if (action === 'input-change') setQuery(val);
        setResultsData([]);
      }}
      blurInputOnSelect
      onChange={({ uri }) => {
        history.push(
          `/${searchType === 'promocodes' ? 'promocode' : 'shops'}/details/${encodeURIComponent(
            uri,
          )}/`,
        );
      }}
      options={resultsData}
      className={`select-rounded ${
        (resultsData.length === 0 && !canShowNoOptions) || !query ? 'no-results' : ''
      }`}
      isLoading={query !== queryDebounced || dataIsPending}
      canShowNoOptions={canShowNoOptions}
      // defaultMenuIsOpen
    />
  );
}

export default withRouter(ProgramsSearchField);
