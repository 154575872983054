import React from 'react';
import { withRouter } from 'react-router-dom';
import ProgramsSearchField from './programs-search-field';
import FilterField from './filter-field';

import './program-filter.scss';
import BulletSlider from '../../shared/bullet-slider/bullet-slider';

function ProgramFilters({
  categories = [],
  types,
  searchType,
  history,
  onChange,
  category,
  country,
  type,
  countries,
}) {
  return (
    <>
      <div className="program-filters">
        <ProgramsSearchField country={countries} searchType={searchType} />
        <div className="program-filters__bottom">
          {/* {types && (
            <FilterField
              id="type-filter"
              label="Тип"
              onChange={(value) => {
                onChange({ type: 'type', value });
              }}
              value={type}
              defaultValue={types[0]}
              options={types}
            />
          )} */}
          {/* {countries && (
            <FilterField
              id="country-filter"
              label="Доставка"
              onChange={(value) => {
                onChange({ type: 'country', value });
              }}
              value={country}
              defaultValue={countries[0]}
              options={countries}
            />
          )} */}
        </div>
      </div>
      <div className="h1">Категории</div>
      <BulletSlider
        value={category.value ? category : categories.find(({ value }) => value === null)}
        options={categories
          .filter(({ value }) => value !== 'fav')
          .map((item) => ({
            ...item,
            url: `/${searchType === 'promocodes' ? 'promocode' : 'shops'}/${
              item.translitName ? `${item.translitName}/` : ''
            }`,
          }))}
        onSelect={(value) => {
          onChange({ type: 'category', value });
        }}
      />
    </>
  );
}

export default withRouter(ProgramFilters);
