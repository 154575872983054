import { useLocation } from 'react-router';
import { useCallback } from 'react';
import useProgramsState from '../../../context/programs-context/hooks/use-programs-state';

export default function useShowMoreUrl() {
  const { next } = useProgramsState();
  const { pathname, search } = useLocation();

  const appendNextParam = useCallback(() => {
    if (!next) return '';
    const searchParams = new URLSearchParams(search);
    searchParams.set('next', next);

    return `${pathname}?${searchParams}`;
  }, [next, pathname, search]);

  return appendNextParam();
}
